<!--
 * @Author: zoujp
 * @Date: 2020-11-19 11:29:40
 * @LastEditTime: 2024-02-07 11:36:34
 * @LastEditors: linshanfeng
 * @Description: 主题-视图下拉选择
 * @FilePath: \user\src\views\themeSet\components\viewComm.vue
-->
<template>

  <div class="title">
        <div :class="{
          'tab-filter-button':picType==1, 'fst-button':picType==1
        }">
        <!-- <div> -->
          <span v-if="picType==1" class="title-text">{{ templateName || '' }}</span>

          <el-dropdown  trigger="click" @command="handlePicTypeChange">
       <span>
        <en-icon
        v-if="picType==1"
            name="iconxiala"
            size="7px"
            style="color: #ffffff; margin-left: 7px"
          ></en-icon>
          <en-icon
          v-if="picType!=1"
          class="en-more"
          name="icongengduo-liebiaoyouce"
          size="13px"
          style="color: A9B5C6"
        ></en-icon>
       </span>
          <el-dropdown-menu slot="dropdown" class="reView">
            <el-dropdown-item :command="1" >
                <div style="display:flex;align-items:center">
                  <div class="putongbak">
                <en-icon name="iconpingpushitu" size="14px" style="color:#3E90FE;"></en-icon>
                </div>
                列表视图
                <en-icon v-if="'1' === picType" name="iconyixuan" size="14px"
                  style="color:#3E90FE;margin-left:10px"></en-icon>
              </div>
            </el-dropdown-item>

            <el-dropdown-item :command="2" >
              <div style="display:flex;align-items:center">
                <div class="putongbak">
              <span class="iconfont" style="color:#FFA32B;font-size: 14px;">&#xe816;</span>
              </div>
              日历视图
              <en-icon v-if="'2' === picType" name="iconyixuan" size="14px"
                style="color:#3E90FE;margin-left:10px"></en-icon>
            </div>
            </el-dropdown-item>
            <el-dropdown-item :command="3" >
              <div style="display:flex;align-items:center">
                <div class="putongbak">
              <span class="iconfont" style="color:#47BAFF;font-size: 14px;">&#xe815;</span>
              </div>
              分组视图
              <en-icon v-if="'3' === picType" name="iconyixuan" size="14px"
                style="color:#3E90FE;margin-left:10px"></en-icon>
            </div>
            </el-dropdown-item>
            <el-dropdown-item :command="5" >
              <div style="display:flex;align-items:center">
                <div class="putongbak">
              <span class="iconfont" style="color:#6B5CE8;font-size: 14px;">&#xe811;</span>
              </div>
              甘特图
              <en-icon v-if="'5' === picType" name="iconyixuan" size="14px"
                style="color:#3E90FE;margin-left:10px"></en-icon>
            </div>
            </el-dropdown-item>
            <el-dropdown-item :command="7" v-if="hasKanBan">
              <div style="display:flex;align-items:center">
                <div class="putongbak">
              <en-icon name="iconkanbanshitu" size="14px" style="color:#A871F6;"></en-icon>
              </div>
              看板视图
              <en-icon v-if="'7' === picType" name="iconyixuan" size="14px"
                style="color:#3E90FE;margin-left:10px"></en-icon>
            </div>
            </el-dropdown-item>
            <!-- <el-dropdown-item :command="8">
              <div style="display:flex;align-items:center">
                <div class="putongbak">
                <span class="iconfont" style="color:#3E90FE;font-size: 14px;">&#xe814;</span>
              </div>
              资源视图
              <en-icon v-if="'8' === picType" name="iconyixuan" size="14px"
                style="color:#3E90FE;margin-left:10px"></en-icon>
            </div>
            </el-dropdown-item> -->

          </el-dropdown-menu>
        </el-dropdown>
        </div>

      </div>
</template>
<script>
import { enService } from "@/api/en/index";

import { themeCommonService } from "@/api";
import { enChart } from "@/api/businessChart";

export default {
name: "viewComm",
props: {
  templateId: {
    type: String
  },
  viewId: {
    type: String
  },
  templateName: {
    type: String
  },
  // 看板页面的特殊处理
  backFlg: {
    type: Boolean,
    default: true
  }
},
data() {
  return {
    picType: this.$route.query.picType || "1", // 看板 or 列表
    vid: this.viewId,
    viewProps: { value: "id", label: "name" },
    viewOptions: [
    { id: "看板视图", name: "看板视图", type: "1" },
    { id: "列表视图", name: "列表视图", type: "2" },
    { id: "日历视图", name: "日历视图", type: "3" },
    { id: "甘特图", name: "甘特图", type: "4" },
    { id: "资源视图", name: "资源视图", type: "8" }
    ],
    picList: [], // 看板视图数据集合
    hasKanBan: false
  };
},
watch: {
  viewId() {
    this.vid = this.viewId;
  }
},
mounted() {
  this.queryUserViewList();
},
methods: {
    /**
     * @description: 查询视图数据
     */
     async queryUserViewList() {
      const rsp = await themeCommonService.queryUserViewList({ templateId: this.$route.query.templateId });
      const arr = rsp || [];
      await this.mdQueryTotalPicList();
      console.log(this.picListm, "this.picListthis.picList");
      if (this.picList.length) {
        this.hasKanBan = true;
        arr.push({ id: "看板视图", name: "看板视图", type: "4" });
      }
      // 没有接口，只能前端自己改数据 如果vid 与列表相同，且模板名称叫 甘特图 添加一个 甘特图列表，type 为5
      arr.push({ id: "甘特图", name: "甘特图", type: "5" });
      this.viewOptions = arr;
      console.log(this.viewOptions);
      this.$emit("toParent", this.viewOptions);
    },
       /**
     * @description: 检查是否有无配置看板
     */
     async mdQueryTotalPicList() {
      const rsp = await themeCommonService.mdQueryTotalPicList({ refId: this.$route.query.templateId, refType: 2 });
      this.picList = rsp || [];
      return Promise.resolve(this.picList);
    },
    /**
     * @description: 跳转
     */
     viewsChange(id) {
      const item = this.viewOptions.find((a) => a.id === id);
      const type = String(item.type);
      let paths = "";
      const query = {
        templateId: this.$route.query.templateId,
        viewId: id,
        templateName: this.$route.query.templateName
      };
      switch (type) {
        case "1":
          // 列表视图
          if (this.backFlg) {
            paths = "viewDefault";
          } else {
            // 看板页面的特殊处理
            paths = "/themeSet/viewDefault";
          }
          break;
        case "2":
          // 日历视图
          paths = "viewCalendar";
          break;
        case "3":
          // 分组视图
          paths = "viewGroup";
          break;
        case "5":
          // 甘特视图
         paths = "gantt";
        break;
        case "8":
          // 资源视图
          console.log("1111111111122222222222");
          this.requestSaveConfig(`picList-${this.$route.query.templateId}`, this.picType);
          this.$router.push({
          path: "/businessModel/resources",
            query: {
            templateId: this.$route.query.templateId,
            templateName: this.$route.query.templateName,
            type: "8",
            viewType: this.$route.query.viewType || "5"
          }
          });
        return;
        case "4":
          // 看板视图
          paths = "/toModelChartUser";
          this.$router.push({
            path: "/toModelChartUser",
            query: {
            templateId: this.$route.query.templateId,
            templateName: this.$route.query.templateName,
            type: "3",
            viewType: this.$route.query.viewType || ""
          }
          });
          return;
      }
      console.log(query);
      this.$router.push({
        path: paths,
        query
      });
    },
    // eslint-disable-next-line consistent-return
    getViewId(type) {
      for (let index = 0; index < this.viewOptions.length; index++) {
        const element = this.viewOptions[index];
        if (element.type === type) {
          return element.id;
        }
      }
    },
    handlePicTypeChange(command) {
        console.log("点击视图类型");
        if (command === 2) { // 任务俩的日历视图
          //
          this.$router.push({ // 日历
            path: "/themeSet/viewCalendar",
            query: {
              templateId: this.$route.query.templateId,
              templateName: this.$route.query.templateName,
              page: this.picType,
              picType: "2",
              viewId: this.getViewId("2"),
              viewType: this.$route.query.viewType
            }
          });
        } else if (command === 5) { // 甘特图
          // 切换看板/列表视图
          this.$router.push({
            path: "/themeSet/gantt",
            query: {
              templateId: this.$route.query.templateId,
              templateName: this.$route.query.templateName,
              page: this.picType,
              picType: "5",
              viewId: this.getViewId("5"),
              viewType: this.$route.query.viewType
            }
          });
        } else if (command === 1) { // 列表
          // 如果只有列表视图，已默认显示，无需触发点击切换重加载
            // 切换看板/列表视图
            this.$router.push({
              path: "/themeSet/viewDefault",
              query: {
                templateId: this.$route.query.templateId,
                templateName: this.$route.query.templateName,
                picType: "1",
                page: this.picType,
                viewId: this.getViewId("1"),
                viewType: this.$route.query.viewType
              }
            });
        } else if (command === 7) { // 看板
          // 如果只有列表视图，已默认显示，无需触发点击切换重加载
            // 切换看板/列表视图
            this.$router.push({
              path: "/toModelChartUser",
              query: {
                templateId: this.$route.query.templateId,
                templateName: this.$route.query.templateName,
                picType: "7",
                type: "3",
                viewId: this.getViewId("7"),
                viewType: this.$route.query.viewType
              }
            });
        } else if (command === 3) { // 分组视图
          // 如果只有列表视图，已默认显示，无需触发点击切换重加载
            // 切换看板/列表视图
            this.$router.push({
              path: "/themeSet/viewGroup",
              query: {
                templateId: this.$route.query.templateId,
                templateName: this.$route.query.templateName,
                picType: "3",
                viewId: this.getViewId("3"),
                viewType: this.$route.query.viewType
              }
            });
        } else if (command === 8) {
          this.requestSaveConfig(`picList-${this.$route.query.templateId}`, this.picType);
          this.$router.push({
          path: "/businessModel/resources",
            query: {
            templateId: this.$route.query.templateId,
            templateName: this.$route.query.templateName,
            picType: "8",
            page: this.picType,
            viewType: this.$route.query.viewType || "5"
          }
          });
        } else {
          // 展开视图设置弹窗
          this.isShowViewSetting = true;
        }
      },
    async requestSaveConfig(key, value) {
      const res = await enService.saveUserConfig({
        configKey: key,
        configValue: value
      });
      return res;
    }

  }
};
</script>
<style lang="scss" scoped>

.btn-left-head {
display: flex;
flex-direction: row;
align-items: center;
}

.left-box {
display: flex;
flex-direction: row;
}

.button-container {
display: flex;
flex-direction: row;
// justify-content: space-between;
width: 40vw;
position: relative;
overflow: hidden;
align-items: center;
}

.arrow {
position: absolute;
width: 30px;
height: 30px;
cursor: pointer;
}

.left {
left: 0;
}

.right {
right: 0;
}

// .content {
//   display: flex;
//   width: calc(100% - 60px);
//   height: 100%;
//   overflow: hidden;
//   margin: 0 30px;
// }

.inner {
display: flex;
transition: transform 0.3s ease;
}

.tab-filter-button {
height: 32px;
background: #FFFFFF;
border: 1px solid #E8ECF2;
border-radius: 16px;
font-size: 14px;
font-weight: 400;
color: #1A1C1E;
line-height: 32px;
margin-right: 20px;
padding: 0px 23px;
text-align: center;
white-space: nowrap;
display: flex;
align-items: center;
}

.fst-button {
background: #3E90FE;
font-size: 14px;
font-weight: 400;
color: #FFFFFF;
}
.el-dropdown-menu .el-dropdown-menu__item{
width: 180px;
}

.title {
  display: flex;
  flex-direction: row;
  padding-left: 1px; //box已有20px
  height: 55px;
  align-items: center;
  .title-text {
    font-size: 14px;
    font-weight:400;
    color: white;
    margin-right: 11px;
  }
}

.putongbak {
  width: 22px;
  height: 22px;
  background: rgba(62, 144, 254, 0.1);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  text-align: center;
}

.tab-filter-button {
  height: 32px;
  background: #ffffff;
  border: 1px solid #e8ecf2;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #1a1c1e;
  cursor: pointer;
  line-height: 32px;
  margin-right: 20px;
  padding: 0px 23px;
  text-align: center;
  white-space: nowrap;
}
.tab-filter-button:hover{
  background:#71b7f8;
  color: #ffffff;
}
.active{
  background: #3e90fe;
  color: #ffffff;
}
.fst-button {
  background: #3e90fe;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}

.en-more{
  outline-color :none;
outline-style:none;
outline-width :none;
}
</style>
