var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "title" }, [
    _c(
      "div",
      {
        class: {
          "tab-filter-button": _vm.picType == 1,
          "fst-button": _vm.picType == 1,
        },
      },
      [
        _vm.picType == 1
          ? _c("span", { staticClass: "title-text" }, [
              _vm._v(_vm._s(_vm.templateName || "")),
            ])
          : _vm._e(),
        _c(
          "el-dropdown",
          {
            attrs: { trigger: "click" },
            on: { command: _vm.handlePicTypeChange },
          },
          [
            _c(
              "span",
              [
                _vm.picType == 1
                  ? _c("en-icon", {
                      staticStyle: { color: "#ffffff", "margin-left": "7px" },
                      attrs: { name: "iconxiala", size: "7px" },
                    })
                  : _vm._e(),
                _vm.picType != 1
                  ? _c("en-icon", {
                      staticClass: "en-more",
                      staticStyle: { color: "A9B5C6" },
                      attrs: { name: "icongengduo-liebiaoyouce", size: "13px" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "reView",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c("el-dropdown-item", { attrs: { command: 1 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "putongbak" },
                        [
                          _c("en-icon", {
                            staticStyle: { color: "#3E90FE" },
                            attrs: { name: "iconpingpushitu", size: "14px" },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" 列表视图 "),
                      "1" === _vm.picType
                        ? _c("en-icon", {
                            staticStyle: {
                              color: "#3E90FE",
                              "margin-left": "10px",
                            },
                            attrs: { name: "iconyixuan", size: "14px" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("el-dropdown-item", { attrs: { command: 2 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("div", { staticClass: "putongbak" }, [
                        _c(
                          "span",
                          {
                            staticClass: "iconfont",
                            staticStyle: {
                              color: "#FFA32B",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("")]
                        ),
                      ]),
                      _vm._v(" 日历视图 "),
                      "2" === _vm.picType
                        ? _c("en-icon", {
                            staticStyle: {
                              color: "#3E90FE",
                              "margin-left": "10px",
                            },
                            attrs: { name: "iconyixuan", size: "14px" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("el-dropdown-item", { attrs: { command: 3 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("div", { staticClass: "putongbak" }, [
                        _c(
                          "span",
                          {
                            staticClass: "iconfont",
                            staticStyle: {
                              color: "#47BAFF",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("")]
                        ),
                      ]),
                      _vm._v(" 分组视图 "),
                      "3" === _vm.picType
                        ? _c("en-icon", {
                            staticStyle: {
                              color: "#3E90FE",
                              "margin-left": "10px",
                            },
                            attrs: { name: "iconyixuan", size: "14px" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _c("el-dropdown-item", { attrs: { command: 5 } }, [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" },
                    },
                    [
                      _c("div", { staticClass: "putongbak" }, [
                        _c(
                          "span",
                          {
                            staticClass: "iconfont",
                            staticStyle: {
                              color: "#6B5CE8",
                              "font-size": "14px",
                            },
                          },
                          [_vm._v("")]
                        ),
                      ]),
                      _vm._v(" 甘特图 "),
                      "5" === _vm.picType
                        ? _c("en-icon", {
                            staticStyle: {
                              color: "#3E90FE",
                              "margin-left": "10px",
                            },
                            attrs: { name: "iconyixuan", size: "14px" },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
                _vm.hasKanBan
                  ? _c("el-dropdown-item", { attrs: { command: 7 } }, [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "putongbak" },
                            [
                              _c("en-icon", {
                                staticStyle: { color: "#A871F6" },
                                attrs: {
                                  name: "iconkanbanshitu",
                                  size: "14px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" 看板视图 "),
                          "7" === _vm.picType
                            ? _c("en-icon", {
                                staticStyle: {
                                  color: "#3E90FE",
                                  "margin-left": "10px",
                                },
                                attrs: { name: "iconyixuan", size: "14px" },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }