<!--
 * @Author: zoujp
 * @Date: 2020-11-19 11:29:40
 * @LastEditTime: 2021-08-25 17:52:30
 * @LastEditors: wuqi
 * @Description: 主题-视图下拉选择
 * @FilePath: \user\src\views\themeSet\components\viewComm.vue
-->
<template>
  <div class="view-select">
    <en-icon v-if="backFlg" name="fanhui" size="small" class="back-btn"></en-icon>
    <en-select v-model="vid" placeholder="请选择" :data="viewOptions" @change="viewsChange" :props="viewProps" size="medium"></en-select>
  </div>
</template>
<script>
import { themeCommonService } from "@/api";

export default {
  name: "viewComm",
  props: {
    templateId: {
      type: String
    },
    viewId: {
      type: String
    },
    templateName: {
      type: String
    },
    // 看板页面的特殊处理
    backFlg: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      vid: this.viewId,
      viewProps: { value: "id", label: "name" },
      viewOptions: [],
      picList: [] // 看板视图数据集合
    };
  },
  watch: {
    viewId() {
      this.vid = this.viewId;
    }
  },
  mounted() {
    this.queryUserViewList();
  },
  methods: {
    /**
     * @description: 查询视图数据
     */
    async queryUserViewList() {
      const rsp = await themeCommonService.queryUserViewList({ templateId: this.templateId });
      const arr = rsp || [];
      await this.mdQueryTotalPicList();
      if (this.picList.length) {
        arr.push({ id: "看板视图", name: "看板视图", type: "4" });
      }
      // 没有接口，只能前端自己改数据 如果vid 与列表相同，且模板名称叫 甘特图 添加一个 甘特图列表，type 为5
      arr.push({ id: "甘特图", name: "甘特图", type: "5" });
      this.viewOptions = arr;
      console.log(this.viewOptions);
      this.$emit("toParent", this.viewOptions);
    },
    /**
     * @description: 检查是否有无配置看板
     */
    async mdQueryTotalPicList() {
      const rsp = await themeCommonService.mdQueryTotalPicList({ refId: this.templateId, refType: 2 });
      this.picList = rsp || [];
      return Promise.resolve(this.picList);
    },
    /**
     * @description: 跳转
     */
    viewsChange(id) {
      const item = this.viewOptions.find((a) => a.id === id);
      const type = String(item.type);
      let paths = "";
      const query = {
        templateId: this.templateId,
        viewId: id,
        templateName: this.templateName
      };
      switch (type) {
        case "1":
          // 列表视图
          if (this.backFlg) {
            paths = "viewDefault";
          } else {
            // 看板页面的特殊处理
            paths = "/themeSet/viewDefault";
          }
          break;
        case "2":
          // 日历视图
          paths = "viewCalendar";
          break;
        case "3":
          // 分组视图
          paths = "viewGroup";
          break;
        case "5":
          // 甘特视图
         paths = "gantt";
        break;
        case "4":
          // 看板视图
          paths = "/toModelChartUser";
          this.$router.push({ path: "/toModelChartUser", query: { templateId: this.templateId, templateName: this.templateName, type: "3" } });
          return;
      }
      console.log(query);
      this.$router.push({
        path: paths,
        query
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-select {
  display: inline-block;
  vertical-align: middle;
  & /deep/ .el-input{
    font-size: 12px;
  }
  .back-btn {
    display: inline-block;
    vertical-align: middle;
    margin-right: 8px;
    color: #bbc4d3;
  }
}
</style>
